<template src="./IdiMeet.html"> </template>

<script>
import { mapGetters } from "vuex";
import idiService from "@/services/Idi";
import configService from "../../configs/config";

export default {
  name: "idi-meet",
  data() {
    return {
      eventIdi: null,
      eventid: "",
      api: [],
      showAudioText: "Activar Microfono",
      shareScreenText: "Compartir Pantalla",
      isComponentModalActive: true,
    };
  },

  created() {
    this.getInitData();
  },

  methods: {
    async getInitData() {
      await this.getEventIdi();
      this.eventid = this.$route.params.idEvent;
    },

    async getEventIdi() {
      this.eventIdi = await idiService.listOneGroup(this.$route.params.idEvent);
      this.validateCode();
    },

    async validateCode() {
      const validate = await idiService.userGroupParticipant(
        this.$route.params.idEvent
      );
      if (validate.participant) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Ingresando a grupo!",
          type: "is-success"
        });
        this.createdJetsi(this.eventIdi);
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "No posees permiso para ingresar",
          type: "is-danger"
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 3000);
      }
    },
    createdJetsi(event) {
      const domain = configService.apiJitsi;
      const options = {
        roomName: event.meetUrl,
        height: 700,
        configOverwrite: {
          startWithAudioMuted: true,
          startWithVideoMuted: true
        },
        interfaceConfigOverwrite: { DISABLE_DOMINANT_SPEAKER_INDICATOR: true },
        parentNode: document.querySelector("#meet"),
        userInfo: {
          displayName: this.user.name
        }
      };
      const api = new JitsiMeetExternalAPI(domain, options);
      this.api = api;
      if (this.api) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Ingreso a la sala con exito!",
          type: "is-success"
        });
        this.isComponentModalActive = false;
      }
    },
    buttonshowAudio(showAudioText) {
      if (showAudioText === "Desactivar Microfono") {
        this.api.executeCommand("toggleAudio");
        this.showAudioText = "Activar Microfono";
      } else if (showAudioText === "Activar Microfono") {
        this.api.executeCommand("toggleAudio");
        this.showAudioText = "Desactivar Microfono";
      }
    },

    buttonShareScreen(shareScreenText) {
      if (shareScreenText === "Compartir Pantalla") {
        this.api.executeCommand("toggleShareScreen");
        this.shareScreenText = "Quitar Pantalla";
      } else if (shareScreenText === "Quitar Pantalla") {
        this.api.executeCommand("toggleShareScreen");
        this.shareScreenText = "Compartir Pantalla";
      }
    }
  },

  computed: {
    ...mapGetters({
      user: "getUser"
    })
  },

};
</script>

<style lang="scss" scoped src="./IdiMeet.scss"></style>
